import { Flashbar, FlashbarProps } from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import toolsTopics from '../../common/tools-content';
import { Type } from '../../interfaces';
import { GlobalState } from '../../redux/reducers';
import { NotificationState } from '../../redux/reducers/notificationReducer';
import { UUID } from '../../util/uuid';
import HomepageContent from './HomepageContent';
import { ServiceNavigation } from '../navigation';
import { appLayoutNavigationLabels } from '../../common/labels';
import './styles.scss';
import HomepageHeader from './homepageHeader';

export default function ServiceHomepage() {
  const [navigationOpen, setNavigationOpen] = useState(false);
  const [items, setItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  const { shouldShowNotification, notification } = useSelector<GlobalState, NotificationState>((state) => state.notifications);
  const [toolsOpen, setToolsOpen] = useState(false);
  const [toolsTopic, setToolsTopic] = useState('lna');
  const { formatMessage } = useIntl();

  const onToolsChangeHandler = (event: any) => setToolsOpen(event.detail.open);

  useEffect(() => {
    if (shouldShowNotification && notification) {
      const object = [
        {
          type: notification.type as Type,
          dismissible: notification.dismissible,
          onDismiss: () => setItems([]),
          content: notification.content,
          header: notification.headerText,
          id: UUID(),
        },
      ];
      setItems(object);
    } else if (!shouldShowNotification) {
      setItems([]);
    }
  }, [shouldShowNotification]);

  return (
    <AppLayout
      headerSelector='#h'
      className='homepage'
      navigation={<ServiceNavigation />}
      content={<HomepageContent />}
      contentHeader={<HomepageHeader />}
      toolsOpen={toolsOpen}
      tools={toolsTopics(toolsTopic, formatMessage)}
      onToolsChange={onToolsChangeHandler}
      navigationOpen={navigationOpen}
      onNavigationChange={event => setNavigationOpen(event.detail.open)}
      ariaLabels={appLayoutNavigationLabels}
      notifications={<Flashbar items={items} />}
    />
  );
}
