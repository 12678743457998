/**
 *
 * @param name {string}
 * @returns the cookie value with the key ends with name
 */
export const getCookieByName = (name: string) => {
  const cookies = Object.fromEntries(document.cookie.split('; ').map(c => c.split('=')));
  const keys = Object.keys(cookies);
  for (const key of keys) {
    if (key.endsWith(name)) {
      return cookies[key];
    }
  }
  return `Not found cookie by name ${name}`;
}