/**
 *
 * @param arr {string[]}
 * @returns true if any value in the array contains only whitespace
 */

export function containsOnlySpaces(arr: string[]) {
  const regex = /^\s*$/; // Regular expression to match strings with only spaces
  return arr.some(s => regex.test(s));
}
