import {
  CollectionPreferencesProps,
  Link,
  RadioGroupProps,
  TableProps,
} from '@amzn/awsui-components-react';

import { IEngagement } from '../../../interfaces';
import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';

export function getMatchesCountText(count: number | undefined, formatMessage?: any): string {
  return count === 1 ? formatMessage({id: '1MatchLabel'}) : formatMessage({id: 'nMatchLabel'}, {countText: count});

}

export function getTableConfig(formatMessage?: any): any {

  const CUSTOM_PREFERENCE_OPTIONS: RadioGroupProps.RadioButtonDefinition[] =
      [
        { value: 'table', label: formatMessage({id: 'tableLabel'}) },
        { value: 'cards', label: formatMessage({id: 'cardsLabel'}) },
      ];

  const DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
    pageSize: 30,
    wrapLines: false,
    custom: CUSTOM_PREFERENCE_OPTIONS[0].value,
    visibleContent: [
      'engagementName',
      'engagementStatus',
      'accountName',
      'completed',
      'engagementCloseDate',
      'lastResponseDate',
      'engagementCreationDate',
      'creatorEmail'
    ],
  };

  const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<IEngagement>[] = [
    {
      id: 'engagementName',
      header: formatMessage({id: 'engagementName'}),
      cell: (item) => (<Link href={`#/engagement-detail/${item.engagementId}`}>{item.engagementName}</Link>),
      sortingField: 'engagementName',
      minWidth: 185
    },
    {
      id: 'engagementStatus',
      header: formatMessage({id: 'status'}),
      cell: (item) => item.engagementStatus,
      sortingField: 'engagementStatus',
      width: 120
    },
    {
      id: 'accountName',
      header: formatMessage({id: 'accountNameLabel'}),
      cell: (item) => <span className='ellipsis'>{item.accountName}</span>,
      sortingField: 'accountName',
      minWidth: 153
    },
    {
      id: 'completed',
      header: formatMessage({id: 'completeResponses'}),
      cell: (item) => (<>{ item.estimatedNumberOfParticipants ? `${item.completedResponses} / ${item.estimatedNumberOfParticipants} `: item.completedResponses}</>),
      sortingField: 'completed',
      sortingComparator: (a, b) => a.completedResponses - b.completedResponses,
      minWidth: 193
    },
    {
      id: 'inProgressResponses',
      header: formatMessage({id: 'inProgressResponses'}),
      cell: (item) => item.inProgressResponses,
      sortingField: 'inProgressResponses',
      minWidth: 204
    },
    {
      id: 'engagementCloseDate',
      header: formatMessage({id: 'surveyCloseDate'}),
      cell: (item) => new Date(item.engagementCloseDate).toISOString().split('T')[0],
      sortingField: 'engagementCloseDate',
      minWidth: 176
    },
    {
      id: 'lastResponseDate',
      header: formatMessage({id: 'lastResponseDate'}),
      cell: (item) => new Date(item.lastResponseDate).toLocaleDateString(),
      sortingField: 'lastResponseDate',
      minWidth: 183
    },
    {
      id: 'engagementCreationDate',
      header: formatMessage({id: 'creationDate'}),
      cell: (item) => new Date(item.engagementCreationDate).toLocaleDateString(),
      sortingField: 'engagementCreationDate',
      minWidth: 150
    },
    {
      id: 'creatorEmail',
      header: formatMessage({id: 'creatorEmail'}),
      cell: (item) => <span className='ellipsis'>{item.engagementCreatedBy}</span>,
      sortingField: 'creatorEmail',
      sortingComparator: (a, b) => a.engagementCreatedBy.localeCompare(b.engagementCreatedBy),
      minWidth: 200
    }
  ];

  const FILTERING_PROPERTIES: readonly PropertyFilterProperty[] = [
    {
      propertyLabel: formatMessage({id: 'engagementStatus'}),
      key: 'engagementStatus',
      groupValuesLabel: formatMessage({id: "engagementStatusGroupValue"}),
      operators: [':', '!:', '=', '!='],
    },
    {
      propertyLabel: formatMessage({id: 'accountNameLabel'}),
      key: 'accountName',
      groupValuesLabel: formatMessage({id: "accountNameGroupValue"}),
      operators: [':', '!:', '=', '!='],
    },
    {
      propertyLabel: formatMessage({id: 'engagementName'}),
      key: 'engagementName',
      groupValuesLabel: formatMessage({id: "engagementNameGroupValue"}),
      operators: [':', '!:', '=', '!='],
    },
    {
      propertyLabel: formatMessage({id: 'closeDate'}),
      key: 'engagementCloseDate',
      groupValuesLabel: formatMessage({id: "closeDateGroupValue"}),
      operators: ['<=', '>='],
    },
    {
      propertyLabel: formatMessage({id: 'lastResponseDate'}),
      key: 'lastResponseDate',
      groupValuesLabel: formatMessage({id: "lastResponseDateGroupValue"}),
      operators: ['<=', '>='],
    },
    {
      propertyLabel: formatMessage({id: 'creationDate'}),
      key: 'engagementCreationDate',
      groupValuesLabel: formatMessage({id: "creationDateGroupValue"}),
      operators: ['<=', '>='],
    },
    {
      propertyLabel: formatMessage({id: 'creatorEmail' }),
      key: 'engagementCreatedBy',
      groupValuesLabel: formatMessage({id: "creatorEmailValue" }),
      operators: ['=', '!=', ':', '!:']
    }
  ];

  const PROPERTY_FILTERING_I18N_CONSTANTS = {
    filteringAriaLabel: formatMessage({id: 'yourChoice'}),
    dismissAriaLabel: formatMessage({id: 'dismiss'}),

    filteringPlaceholder: formatMessage({id: 'searchEngagementsText'}),
    groupValuesText: formatMessage({id: "groupValues"}),
    groupPropertiesText: formatMessage({id: 'properties'}),
    operatorsText: formatMessage({id: 'operators'}),

    operationAndText: formatMessage({id: "and"}),
    operationOrText: formatMessage({id: "or"}),

    operatorLessText: formatMessage({id: "lessThan"}),
    operatorLessOrEqualText: formatMessage({id: "lessThanOrEqual"}),
    operatorGreaterText: formatMessage({id: "greaterThan"}),
    operatorGreaterOrEqualText: formatMessage({id: "greaterThanOrEqual"}),
    operatorContainsText: formatMessage({id: "contains"}),
    operatorDoesNotContainText: formatMessage({id: "doesNotContain"}),
    operatorEqualsText: formatMessage({id: "equals"}),
    operatorDoesNotEqualText: formatMessage({id: "doesNoteEqual"}),

    editTokenHeader: formatMessage({id: "editFilter"}),
    propertyText: formatMessage({id: "property"}),
    operatorText: formatMessage({id: "operator"}),
    valueText: formatMessage({id: "value"}),
    cancelActionText: formatMessage({id: "cancel"}),
    applyActionText: formatMessage({id: "apply"}),
    allPropertiesLabel: formatMessage({id: "allProperties"}),

    tokenLimitShowMore: formatMessage({id: "showMore"}),
    tokenLimitShowFewer: formatMessage({id: "showFewer"}),
    clearFiltersText: formatMessage({id: "clearFilters"}),
    removeTokenButtonAriaLabel: () => formatMessage({id: "removeToken"}),
    enteredTextLabel: (text: any) => formatMessage({id: "useTextLabel"}, { text: `${text}`}),
  };

  const PAGINATION_LABELS = {
    nextPageLabel: formatMessage({id: "nextPage"}),
    pageLabel: (pageNumber: number) => formatMessage({id: "goToPage"}, { pageNumber: `${pageNumber}`}),
    previousPageLabel: formatMessage({id: "previousPage"}),
  };

  const CONTENT_SELECTOR_OPTIONS: CollectionPreferencesProps.VisibleContentOptionsGroup[] =
      [
        {
          label: formatMessage({id: "mainEngagementProperties"}),
          options: [
            { id: 'engagementName', label: formatMessage({id: "engagementName"}), editable: false },
            { id: 'engagementStatus', label: formatMessage({id: "engagementStatus"}), editable: true },
            { id: 'accountName', label: formatMessage({id: "accountNameLabel"}), editable: false },
            { id: 'completed', label: formatMessage({id: "completedUpper"}), editable: true },
            { id: 'inProgressResponses', label: formatMessage({id: "inProgressResponses"}), editable: true },
            { id: 'engagementCloseDate', label: formatMessage({id: "surveyCloseDate"}), editable: true },
            { id: 'lastResponseDate', label: formatMessage({id: "lastResponseDate"}), editable: true },
            { id: 'engagementCreationDate', label: formatMessage({id: "creationDate"}), editable: true },
            { id: 'creatorEmail', label: formatMessage({id: "creatorEmail"}), editable: true}
          ],
        },
      ];

  const PAGE_SELECTOR_OPTIONS: CollectionPreferencesProps.PageSizeOption[] =
      [
        { value: 10, label: formatMessage({id: "10engagements"}) },
        { value: 30, label: formatMessage({id: "30engagements"}) },
        { value: 50, label: formatMessage({id: "50engagements"}) },
      ];

  return {
    contentSelectorOptions: CONTENT_SELECTOR_OPTIONS,
    defaultPreferences: DEFAULT_PREFERENCES,
    columnDefinitions: COLUMN_DEFINITIONS,
    filteringProperties: FILTERING_PROPERTIES,
    propertyFilteringI18nConstants: PROPERTY_FILTERING_I18N_CONSTANTS,
    paginationLabels: PAGINATION_LABELS,
    pageSelectorOptions: PAGE_SELECTOR_OPTIONS
  }
}
