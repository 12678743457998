import { Header } from '@amzn/awsui-components-react';

export type FormHeadProps = {
  updateTools?: any;
  isEditMode: boolean;
  isReopenMode: boolean;
};

const LNACreateFormHeader = ({ isEditMode, isReopenMode }: FormHeadProps) => {
  const description = isEditMode
    ? `Modify and save the form to make changes to this learning needs analysis survey.`
    : isReopenMode
          ? `Modify and save the form to reopen to this learning needs analysis survey.`
          : `Complete the form below to create a new learning needs analysis survey.`;

  const content = isEditMode
    ? `Edit engagement`
    : isReopenMode
          ? `Reopen engagement`
          : `Create engagement`;

  return (
    <Header
      variant='h1'
      description={description}
    >
      {content}
    </Header>
  );
};

export default LNACreateFormHeader;
