import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import Cache from '@aws-amplify/cache';
import * as configs from '../config/gandalfConfig.json';
import store from '../redux/store';

export const createUrlInterceptor =
    ({ domain, login, logout }: { domain: string; login: string; logout: string }) =>
    (url: string) => {
        const SELF = '_self';
        const loginRequestUrl = `https://${domain}/oauth2/authorize`;
        const logoutRequestUrl = `https://${domain}/logout`;
        let windowProxy;

        if (login && url.startsWith(loginRequestUrl)) {
            const signInUrl = url.replace(loginRequestUrl, `https://${login}/login`);
            windowProxy = window.open(signInUrl, SELF);
        } else if (logout && url.startsWith(logoutRequestUrl)) {
            const signOutUrl = url.replace(logoutRequestUrl, `https://${logout}/logout`);
            windowProxy = window.open(signOutUrl, SELF);
        } else {
            windowProxy = window.open(url, SELF);
        }
        return windowProxy ? Promise.resolve(windowProxy) : Promise.reject();
    };

export const getStage = () => {
    const url = `${window.location.origin}`;
    if (url.includes('beta')) {
      return 'beta';
    } else if (url.includes('gamma')) {
      return 'gamma';
    } else if (url.includes('prod')) {
      return 'prod';
    } else {
      return 'dev';
    }
};

export const getConfigure = () => {
    const stage = getStage();
    if (stage === 'gamma') {
        return configs.gamma;
    } else if (stage === 'beta') {
      return configs.beta;
    } else {
      return configs.dev;
    }
};

const config = getConfigure();

/**
 * Setup amplify config
 */
const AMPLIFY_CONFIG = {
    Auth: {
        region: config.Auth.region,
        userPoolId: config.Auth.userPoolId,
        userPoolWebClientId: config.Auth.userPoolWebClientId,
        oauth: {
            domain: config.Auth.oauth.domain,
            scope: config.Auth.oauth.scope,
            redirectSignIn: getStage() === 'dev' ? `${window.location.origin}` : config.Auth.oauth.redirectSignIn,
            redirectSignOut: getStage() === 'dev' ? `${window.location.origin}` : config.Auth.oauth.redirectSignOut,
            responseType: config.Auth.oauth.responseType,
            codeChallengeMethod: 'S256',
            codeChallenge: encodeURI(btoa(Math.random().toString())),
            urlOpener: createUrlInterceptor(
                {
                    domain: config.Auth.oauth.domain,
                    login: config.Gandalf.customSignInDomain,
                    logout: config.Gandalf.customSignOutDomain
                }
            ),
        },
    },
};

export const configureAmplify = () => {
    Amplify.configure(AMPLIFY_CONFIG);
};
/**
 * Checks if there is a current authenticated user.
 * Auth.currentAuthenticatedUser returns a valid jwtToken and if different
 * from Cache federatedInfo, update the cache with the valid token
 */
export const hasValidToken = async () => {
    try {
        const cognitoIdToken = (await Auth.currentAuthenticatedUser({ bypassCache: true }))
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken();
        const currentToken = Cache.getItem('federatedInfo').token;
        if (currentToken !== cognitoIdToken) {
            Cache.setItem('federatedInfo', { token: cognitoIdToken });
        }
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
};

export const redirectToLoginPage = () => {
    configureAmplify();
    Auth.federatedSignIn();
};
