import { strEnum } from '../common/str-enum';

export interface ContentOrigin {
  value: string;
  label: string;
}

export interface Distribution {
  id: string;
  deliveryMethod: string;
  domainName: string;
  origin: string;
  status: string;
  state: 'Disabled' | 'Enabled';
  priceClass: string;
  logging: 'on' | 'off';
  sslCertificate: 'Default' | 'Custom';
}

// Is of type T or undefined. Used for '?' properties of TS.
export type Optional<T> = T | undefined;

export interface IEngagement {
  accountName: string;
  accountSFDCId: string;
  engagementId: string;
  engagementName: string;
  engagementCreationDate: number;
  engagementCloseDate: number;
  engagementCreatedBy: string;
  engagementStatus: string;
  engagementType: string;
  teams: string[];
  estimatedNumberOfParticipants?: number;
  inProgressResponses: number;
  completedResponses: number;
  lastResponseDate: number;
  isThisLNAForAnATP: boolean;
  displayIndividualRecommendations: boolean;
  internalAWSTestEngagement: boolean;
  surveyId: string;
  surveyPreviewLink: string;
  surveyDistributionLink: string;
  responseDownloadLink: string;
  collaborators?: string[];
}

export const ApiHookStatuses = strEnum(['idle', 'loading', 'error', 'success']);

export type ApiHookStatus = keyof typeof ApiHookStatuses;

export type ApiHookResult<T, S = Error> = {
  data: T | undefined;
  error: S;
  status: ApiHookStatus;
  refetch?: any;
  isLoading?: boolean;
  isFetching?: boolean;
  isSuccess?: boolean;
};

export type ApiMutateHookResult<T, A, S> = {
  mutate: (a: A, b?: any) =>void;
} & ApiHookResult<T, S>;

export type Type = 'success' | 'warning' | 'info' | 'error';

export interface IEngagementDetail {
  percentCompleted: string;
  inProgress: number;
  completed: number;
  total: number;
  accountName: string;
  sfdcID: string;
  closeDate: string;
  type: string;
  status: string;
  isIndividual: boolean;
  isTest: boolean;
  teams: string[];
  engagementId: string;
  isAtp: boolean;
  creatorEmail: string;
  collaborators?: string[];
}