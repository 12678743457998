import { GlobalState } from '../../redux/reducers';
import { IEngagement } from '../../interfaces';
import { SERVER_TAKE_TOO_LONG } from '../../common/const';
import { delay } from '../../util/delay';
import { getCookieByName } from '../../util/cookies';
import { isJsonString } from '../../util/isJsonString';
import store from '../../redux/store';
import { displayAtpFeature } from '../../util/featureControlHelper';
import Cache from '@aws-amplify/cache';

export type ListEngagementsResponse = {
  engagements: IEngagement[];
  pageSize: number;
  totalItem: number;
};

export type GetEngagementRequest = {
  engagementId: string;
};

export type GetEngagementResponse = IEngagement;

export type CreateEngagementRequest = {
  accountName: string;
  accountSFDCId: string;
  engagementName: string;
  engagementCloseDate: number;
  engagementType: string;
  teams: string[];
  estimatedNumberOfParticipants?: number;
  isThisLNAForAnATP: boolean;
  displayIndividualRecommendations: boolean;
  internalAWSTestEngagement: boolean;
  collaborators?: string[];
};

export type CreateEngagementResponse = {
  engagementId: string;
};

export type DownloadResponseRequest = {
  engagementId: string;
};

// https://code.amazon.com/packages/LNAService/blobs/mainline/--/src/main/java/com/amazon/lnaservice/pojo/UpdateEngagementRequest.java
export type EditEngagementRequest = {
  engagementId: string;
  accountName: string;
  accountSFDCId: string;
  engagementName: string;
  engagementCloseDate: number;
  teams: string[];
  estimatedNumberOfParticipants?: number;
  collaborators?: string[];
};

export type EditEngagementResponse = {
  engagementId: string;
};

export type ReopenEngagementRequest = {
  engagementId: string;
  engagementCloseDate: number;
};

export type ReopenEngagementResponse = {
  engagementId: string;
};

/**
 * @todo Right now We are reading the api endpoint in a asynchronous way, and we need to call the listEngagements at the beginning of page loads.
 *       And some time we also need to call get Engagement at the page loads, both API needs to know the endpoint.
 *       Race condition:
 *        1. fetch('/settings.json') -> retrieve API endpoint information
 *        2. listEngagements('${apiEndpoint}') -> this one needs the results from 1
 *
 *       We need to make sure to run the listEngagements after the 1 finish, at the time 1 finish, it will dispatch a state change to Redux store
 *       We read the api endpoint from the redux store, but since this is asynchronous. We currently have a work around is to wait half seconds
 *
 * @Solution We want to figure out how to insert the endpoint information into Front end's meta tag so we can read it in synchronous way, And this can be done
 */
class EngagementsDAO {

  stage!: string;
  endpoint!: string;
  state!: GlobalState;

  constructor() {
    this.init();
  }

  init = () => {
    const data = store.getState();
    this.stage = data.api.stage;
    this.state = data;
    this.endpoint = data.api.endpoint;
  }

  /**
   *
   * @param url {string} The URL endpoint that API will call to
   * @param config {object} https://developer.mozilla.org/en-US/docs/Web/API/fetch
   * @param delayTime {number} How long this API will wait before it call the endpoint, this is temporary, to wait to other config files finish loading
   * @param errorMessage {string} The default error message
   * @returns {JSON} The response, right now only expect all data returned as JSON
   */
  call = async (url: string, config?: RequestInit, delayTime?: number, errorMessage?:string,): Promise<any> => {
    const showAtpFeature = displayAtpFeature();
    const authToken = showAtpFeature == true ? Cache.getItem('federatedInfo').token : getCookieByName('idToken');

    if (delayTime) {
      await delay(delayTime);
    }
    // Magic, don't delete this if block.
    // Don't know why I need to call this init again, but without it, api will fail
    if (this.endpoint === '') {
      this.init();
    }
    const configWithToken = { ...config, headers: { 'Authorization': authToken }}
    const response = await fetch(`${this.endpoint}/${url}`, configWithToken);
    if (response.ok) {
      return response.json();
    }
    // If we get 504 error during create engagement call, we use the url and POST method to identify engagement creation api call, this might change in the future
    if (response.status === 504 && url === 'engagements' && config?.method === 'POST') {
      throw new Error(SERVER_TAKE_TOO_LONG);
    }
    return response.text().then( text => {
      if (isJsonString(text)) {
        const obj = JSON.parse(text);
        throw new Error(obj.Message || obj.message || errorMessage);
      } else {
        throw new Error(text);
      }
    });
  };

  listEngagements = async (): Promise<ListEngagementsResponse> => {
    return this.call('engagements', {
      method: 'GET',
      mode: 'cors',
    }, 500, 'list engagements failed');
  }

  /**
   *
   * @param req {GetEngagementRequest}
   * @returns IEngagement[]
   */
  getEngagement = async (req: GetEngagementRequest): Promise<IEngagement> => {
    return this.call(`engagements/${req.engagementId}`, {
      method: 'GET',
      mode: 'cors'
    }, 500, 'get engagement failed');
  }

  async createEngagement(req: CreateEngagementRequest): Promise<CreateEngagementResponse> {
    return this.call(`engagements`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(req)
    }, 0, 'create engagement failed');
  }

  async downloadResponse(req: DownloadResponseRequest): Promise<any> {
    return this.call(`engagements/${req.engagementId}/report`, {
      method: 'GET',
      mode: 'cors'
    }, 0, 'get report failed');
  }

  async editEngagement(req: EditEngagementRequest): Promise<EditEngagementResponse> {
    const requestBody: any = {
      accountName: req.accountName,
      accountSFDCId: req.accountSFDCId,
      engagementCloseDate: req.engagementCloseDate,
      teams: req.teams,
      engagementName: req.engagementName,
      collaborators: req.collaborators
    };
    if (req.estimatedNumberOfParticipants) {
      requestBody['estimatedNumberOfParticipants'] = req.estimatedNumberOfParticipants;
    }
    return this.call(`engagements/${req.engagementId}`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    }, 0, 'update engagement failed');
  }

  async reopenEngagement(req: ReopenEngagementRequest): Promise<ReopenEngagementResponse> {
    const requestBody: any = {
      engagementCloseDate: req.engagementCloseDate,
    };
    return this.call(`engagements/${req.engagementId}/reopen`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    }, 0, 'reopen engagement failed');
  }
}

export const engagementsDAO = new EngagementsDAO();
