/// <reference path="../../index.d.ts" />
import { Container, Link, Grid, Icon, SpaceBetween } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { LINK_1, LINK_2, LINK_3, LINK_4, LINK_5, LINK_WIKI } from '../../common/const';
import logo from './LNA_Roadmap.png';


export default function HomepageContent() {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const gotoCreateLNA = () => {
    navigate('/lna-creation');
  };

  return (
    <SpaceBetween size='l'>
          <Grid
            gridDefinition={[{ colspan: { m: 8, xxs: 12} }, { colspan: { m: 4, xxs: 12} }]}
            className='homepage-content'
          >
            <div className='left'>
              <h2>All about LNAs</h2>
              <Container>
                <h3>{formatMessage({ id: 'homeQ1' })}</h3>
                <p className='bottom-grey'>{formatMessage({ id: 'homeA1' })}</p>
                <h3>{formatMessage({ id: 'homeQ2' })}</h3>
                <p>{formatMessage({ id: 'homeA2' })}</p>
                <ul>
                  <li>{formatMessage({ id: 'homeA2a' })}</li>
                  <li>{formatMessage({ id: 'homeA2b' })}</li>
                  <li>{formatMessage({ id: 'homeA2c' })}</li>
                  <li>{formatMessage({ id: 'homeA2d' })}</li>
                  <li>{formatMessage({ id: 'homeA2e' })}</li>
                </ul>
                <p>{formatMessage({ id: 'homeA2f' })}</p>
                <h3>{formatMessage({ id: 'homeQ3' })}</h3>
                <p>{formatMessage({ id: 'homeA3' })}</p>
                <img src={logo} alt='LNA process' className='logo' />
              </Container>
            </div>
            <div className='right'>
              <Container>
                <h2>{formatMessage({ id: 'lnaResources' })}</h2>
                <Link href={LINK_WIKI}>{formatMessage({ id: 'wiki' })}</Link>
                <Link href={LINK_1}>{formatMessage({ id: 'link1' })}</Link>
                <Link href={LINK_2}>{formatMessage({ id: 'link2' })}</Link>
                <Link href={LINK_3}>{formatMessage({ id: 'link3' })}</Link>
                <Link href={LINK_4}>{formatMessage({ id: 'link4' })}</Link>
                <Link href={LINK_5}>{formatMessage({ id: 'link5' })}</Link>
              </Container>
            </div>
          </Grid>
    </SpaceBetween>

  );
}
