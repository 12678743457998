import './index.scss';

import { AppLayout, Flashbar, FlashbarProps } from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';

import BreadCrumbs from '../../../common/breadcrumbs';
import EngagementsContainer from './engagementsContainer';
import { GlobalState } from '../../../redux/reducers';
import { NotificationState } from '../../../redux/reducers/notificationReducer';
import { ServiceNavigation } from '../../navigation';
import { Type } from '../../../interfaces';
import { UUID } from '../../../util/uuid';
import toolsTopics from '../../../common/tools-content';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const Engagements = () => {

    const [navigationOpen, setNavigationOpen] = useState(false);
    const [toolsOpen, setToolsOpen] = useState(false);
    const { shouldShowNotification, notification } = useSelector<GlobalState, NotificationState>(state => state.notifications);
    const [items, setItems] = useState<FlashbarProps.MessageDefinition[]>([]);

    useEffect(() => {
      if (shouldShowNotification && notification) {
        const object = [{
          type: notification.type as Type,
          dismissible: notification.dismissible,
          onDismiss: () => setItems([]),
          content: notification.content,
          header: notification?.headerText,
          id: UUID(),
        }];
        setItems(object);
      } else if (!shouldShowNotification) {
        setItems([]);
      }
    }, [shouldShowNotification]);

    const { formatMessage } = useIntl();

    return (
        <AppLayout
            toolsOpen={toolsOpen}
            breadcrumbs={BreadCrumbs("engagementListsBreadcrumbs", formatMessage)}
            onToolsChange={event => setToolsOpen(event.detail.open)}
            tools={toolsTopics('engagements', formatMessage)}
            navigation={<ServiceNavigation />}
            navigationOpen={navigationOpen}
            onNavigationChange={event => setNavigationOpen(event.detail.open)}
            content={<EngagementsContainer />}
            notifications={<Flashbar items={items} />}
        />
    );
};

export default Engagements;
