import { ApiHookStatuses, IEngagement } from '../../../interfaces';
import { useEffect, useState } from 'react';

import EngagementsComponent from './engagementsComponent';
import { useListEngagements } from '../../../services/api/engagements-hook';

const EngagementsContainer = () => {
  const [allItems, setAllItems] = useState<IEngagement[]>();
  const [loading, setLoading] = useState<boolean>(true); // initial status should be true, otherwise if will show no engagement in the list at the very beginning
  const [fetchError, setFetchError] = useState<any>();
  const { status, error, data, refetch: listEngagementsRefetch } = useListEngagements();

  useEffect(() => {
    if (status === ApiHookStatuses.loading) {
      setLoading(true);
    }
    if (status === ApiHookStatuses.error && error) {
      setLoading(false);
      setFetchError(error.message);
    }
    if (status === ApiHookStatuses.success && data) {
      setLoading(false);
      setAllItems(data.engagements);
    }
  }, [status, data]);

  return (
    <EngagementsComponent
      fetchError={fetchError}
      allItems={allItems}
      loading={loading}
      loadEngagements={listEngagementsRefetch}
    />
  );
};

export default EngagementsContainer;
