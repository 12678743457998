import SideNavigation, {
  SideNavigationProps,
} from '@amzn/awsui-components-react/polaris/side-navigation';
import { WithRouterProps, withRouter } from '../../hooks/withRouter';
import { useLocation, useNavigate } from 'react-router-dom';

import { useIntl } from 'react-intl';

// Class ServiceNavigation is the Side Navigation component that is used in BasicLayout, CreateForm, ServiceHomepage, and Table flows.
// Implement like this: <ServiceNavigation />
const ServiceNavigationLocal  = () => {

  const { formatMessage } = useIntl();

  const HOME = { text: formatMessage({ id: 'lna' }), href: '#/' };

  const items: SideNavigationProps.Item[] = [
    {
      type: 'section',
      text: formatMessage({ id: 'lna' }),
      items: [
        { type: 'link', text: formatMessage({ id: 'createLna' }), href: '#/lna-creation' },
        { type: 'link', text: formatMessage({ id: 'engagements' }), href: '#/engagements' }
      ],
    }
  ];

  const navigate = useNavigate();
  const location = useLocation();
  // If the provided link is empty, do not redirect pages
  const onFollowHandler = (event: CustomEvent<SideNavigationProps.FollowDetail>) => {
    event.preventDefault();
    if (event.detail.href) {
      navigate(event.detail.href.substring(1));
    }
  }

    return (
      <SideNavigation
        header={HOME}
        items={items}
        activeHref={`#${location.pathname}`}
        onFollow={onFollowHandler.bind(this)}
      />
    );
}

export const ServiceNavigation = withRouter(ServiceNavigationLocal);
