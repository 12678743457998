import apiReducer from './reducers/apiReducer';
import { configureStore } from '@reduxjs/toolkit';
import languageReducer from './reducers/languageReducer';
import notificationReducer from './reducers/notificationReducer';

const store = configureStore({
  reducer: {
    language: languageReducer,
    notifications: notificationReducer,
    api: apiReducer
  }
});

export default store;
