import { ApiHookStatuses, IEngagement, Optional } from '../../../interfaces';
import {
  Button,
  Checkbox,
  Container,
  DatePicker,
  Form,
  FormField,
  Header,
  Input,
  Link,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { EditEngagementRequest, ReopenEngagementRequest } from '../../../services/DAO/engagementsDAO';
import { deleteNotification, showNotification } from '../../../redux/reducers/notificationReducer';
import { useCreateEngagement, useEditEngagement, useReopenEngagement } from '../../../services/api/engagements-hook';
import { useEffect, useState } from 'react';

import CancelModal from '../../../modals/cancelModal';
import { DynamicInputFields } from '../../../common/dynamic-input-fields';
import { SERVER_TAKE_TOO_LONG } from '../../../common/const';
import { UUID } from '../../../util/uuid';
import { containsOnlySpaces } from '../../../util/containEmptyString';
import { encodedStr } from '../../../util/html-encoding';
import { getDirtyStringValues } from '../../../util/getDirtyStringValues';
import { isAtLeastTomorrow } from '../../../util/isAtLeastTmr';
import { isEmailValid } from '../../../util/isValidEmail';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

/**
 * updateTools, setup the help panel content by passing a topic id.
 */
type LNAFormProps = {
  isEditMode: boolean;
  isReopenMode: boolean;
  value?: IEngagement;
  updateTools?: (topic: string) => void;
  displayCollaborator?: boolean;
};

const ADayInMilliseconds = (23 * 60 * 60 * 1000) + (59 * 60 * 1000);

const LnaFormContent = (props: LNAFormProps) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutate: createMutate, status: createStatus, error: createError } = useCreateEngagement();
  const { mutate: editMutate, status: editStatus, error: editError } = useEditEngagement();
  const { mutate: reopenMutate, status: reopenStatus, error: reopenError } = useReopenEngagement();
  const { isEditMode, isReopenMode, value, updateTools, displayCollaborator } = props;

  const handleMutationResult = useCallback((status: any, error: any, successMessage: string, navigateTo: string, errorMessageHeader?: string) => {
    if (status === ApiHookStatuses.error && error) {
      setBtnLoading(false);
      if (error.message === SERVER_TAKE_TOO_LONG) {
        navigate('/engagements');
      }
      dispatch(deleteNotification());
      dispatch(showNotification({
        type: 'error',
        dismissible: true,
        headerText: formatMessage({ id: error.message === SERVER_TAKE_TOO_LONG ? '504ErrorCreation' : errorMessageHeader }),
        content: error.message,
        id: UUID(),
        displayInNewLocation: error.message === SERVER_TAKE_TOO_LONG ? true : false,
        displayInNewLocationDone: false
      }));
    }
    if (status === ApiHookStatuses.success) {
      setBtnLoading(false);
      navigate(navigateTo);
      dispatch(showNotification({
        type: 'success',
        dismissible: true,
        content: formatMessage({ id: successMessage }),
        id: UUID(),
        displayInNewLocation: true,
        displayInNewLocationDone: false
      }));
    }
  }, [dispatch, formatMessage, navigate]);

  // Handle the create engagement mutate results
  useEffect(() => {
    handleMutationResult(createStatus, createError, 'engagementCreated', '/engagements', 'createEngagementFailedHeader');
  }, [createStatus, handleMutationResult]);

  // Handle the edit engagement mutate results
  useEffect(() => {
    handleMutationResult(editStatus, editError, 'editSucceed', `/engagement-detail/${value?.engagementId}`, 'editFailed');
  }, [editStatus, handleMutationResult, value]);

  // Handle the reopen engagement mutate results
  useEffect(() => {
    handleMutationResult(reopenStatus, reopenError, 'reopenSucceed', `/engagement-detail/${value?.engagementId}`, 'reopenFailed');
  }, [reopenStatus, handleMutationResult, value]);


  let type = {};
  if (value && value.isThisLNAForAnATP) {
    type = { value: 'Partner', label: formatMessage({ id: 'partner' }), description: formatMessage({ id: 'typeDescriptionP' }) };
  } else if (value && !value.isThisLNAForAnATP) {
    type = { value: 'Customer', label: formatMessage({ id: 'customer' }), description: formatMessage({ id: 'typeDescriptionC' }) };
  }

  const [formError, setFormError] = useState({
    accountName: '',
    sfdcID: '',
    engagementName: '',
    closeDate: '',
    numberOfParticipants: '',
    engagementType: '',
    teamNames: '',
    collaborators: ''
  });

  let displayTime = '';
  if (value?.engagementCloseDate) {
    const utcTime = new Date(value.engagementCloseDate).toISOString();
    displayTime = utcTime.split('T')[0];
  }

  const [accountName, setAccountName] = useState<string>(value?.accountName || '');
  const [sfdcID, setSfdcId] = useState<string>(value?.accountSFDCId || '');
  const [engagementName, setEngagementName] = useState<string>(value?.engagementName || '');
  const [closeDate, setCloseDate] = useState<string>(displayTime);
  const [numberOfParticipants, setNumberOfParticipants] = useState<string>(`${value?.estimatedNumberOfParticipants}` || '');
  const [engagementType, setEngagementType] = useState<Optional<any>>(value ? type : undefined);
  const [displayIndividual, setDisplayIndividual] = useState<boolean>(value?.displayIndividualRecommendations || false);
  const [internal, setInternal] = useState<boolean>(value?.internalAWSTestEngagement || false);
  const [isAtp, setIsAtp] = useState<boolean>(value?.isThisLNAForAnATP || false);
  const [teamNames, setTeamNames] = useState<any[]>(['']);
  const [btnLoading, setBtnLoading] = useState<boolean>(false); // When user submit form, we need to disable button, preventing user double submit
  const [visible, setVisible] = useState<boolean>(false); // Control the cancel modal visibility
  const [collaborators, setCollaborators] = useState<any[]>([]);

  const checkFormError = (): boolean => {

    // We need to verify all team fields and collaborators fields, to address one situation that is user click add button but leaves the button untouched.
    // If not, even backend will throw error, but no error is shown on frontend.
    teamNames.map(item => {item.dirty = true; return item});
    collaborators.map(item => { item.dirty = true; return item});

    return (
      validateAccountName() &&
      validateSDFC() &&
      validateEngagementName() &&
      validateDate() &&
      validateEngagementType() &&
      validateEstimatedNumberOfParticipants() &&
      validateTeamNames() &&
      !allEmptyArray(teamNames) &&
      validateEmail()
    );
  };

  /**
   * This set the cancel modal invisible when user click the nevermind button on cancel modal
   */
  const onCancelHandler = () => {
    setVisible(false);
  };

  const onDismissHandler = () => {
    setVisible(false);
  };

  const openCancelModal = () => {
    setVisible(true);
  };

  /**
   * This controls the behavior when user click the confirm button on Cancel modal
   * The current behavior is to router user to engagement list page
   */
  const onConfirmHandler = () => {
    navigate('/engagements');
  };

  const onSubmitHandler = () => {
    if (!checkFormError()) return;

    setBtnLoading(true);

    const engagementData = {
      accountName: encodedStr(accountName),
      accountSFDCId: encodedStr(sfdcID),
      engagementName: encodedStr(engagementName),
      engagementCloseDate: new Date(closeDate).getTime() + ADayInMilliseconds,
      engagementType: engagementType.value,
      teams: teamNames.map((item) =>  encodedStr(item.value)),
      collaborators: collaborators.map((item) => encodedStr(item.value)),
      estimatedNumberOfParticipants: Number(numberOfParticipants),
      isThisLNAForAnATP: isAtp,
      displayIndividualRecommendations: displayIndividual,
      internalAWSTestEngagement: internal,
    };

    if (value) {
      if (isEditMode) {
        const editBody: EditEngagementRequest = {
          ...engagementData,
          engagementId: value.engagementId,
        };
        editMutate(editBody);
      } else if (isReopenMode) {
        const reopenBody: ReopenEngagementRequest = {
          engagementId: value.engagementId,
          engagementCloseDate: engagementData.engagementCloseDate,
        };
        reopenMutate(reopenBody);
      }
    } else if (!isEditMode) {
      createMutate(engagementData);
    }
  };

  const sfdcInfoHandler = (e: Event) => {
    if (updateTools) {
      updateTools('sfdc');
    }
  };

  const collaboratorInfoHandler = (e: Event) => {
    if (updateTools) updateTools('collaboration');
  }

  const setFormErrorForField = (field: string, messageId: string) => {
    setFormError({ ...formError, [field]: formatMessage({ id: messageId }) });
  };

  const clearFormErrorForField = (field: string) => {
    setFormError({ ...formError, [field]: '' });
  };

  const validateAccountName = (): boolean => {
    if (!accountName.length) {
      setFormErrorForField('accountName', 'accountNameEmpty');
      return false;
    }
    if (accountName.length < 3 || accountName.length > 500) {
      setFormErrorForField('accountName', 'accountNameLength');
      return false;
    }
    clearFormErrorForField('accountName');
    return true;
  };

  const validateSDFC = (): boolean => {
    if (!sfdcID.length) {
      setFormErrorForField('sfdcID', 'sfdcEmpty');
      return false;
    }
    if (sfdcID.length !== 18 || sfdcID.indexOf(' ') >= 0) {
      setFormErrorForField('sfdcID', sfdcID.length !== 18 ? 'sfdcLength' : 'whiteSpace');
      return false;
    }
    clearFormErrorForField('sfdcID');
    return true;
  };

  const validateEngagementName = (): boolean => {
    if (!engagementName.length) {
      setFormErrorForField('engagementName', 'engagementNameEmpty');
      return false;
    }
    if (engagementName.length < 3 || engagementName.length > 500) {
      setFormErrorForField('engagementName', 'engagementNameLength');
      return false;
    }
    clearFormErrorForField('engagementName');
    return true;
  };

  /**
   * Validates the close date and returns true if it's valid, false otherwise.
   * If the close date is invalid, a form error is set.
   * Valid if the date is, non empty, a date in future
   *
   * @returns {boolean} Whether the close date is valid or not.
   */
  const validateDate = (): boolean => {
    // Check if the close date is empty or null
    if (!closeDate) {
      setFormErrorForField('closeDate', 'closeDateEmpty');
      return false;
    }

    // Check if the close date is at least one day ahead of the current date, or today if is edit mode
    if (!isAtLeastTomorrow(closeDate)) {
      setFormErrorForField('closeDate', 'closeDateInvalid');
      return false;
    }
    // Clear any previous form errors for the close date and return true
    clearFormErrorForField('closeDate');
    return true;
  };

  const validateEngagementType = (): boolean => {
    if (engagementType === undefined) {
      setFormErrorForField('engagementType', 'engagementTypeEmpty');
      return false;
    }
    clearFormErrorForField('engagementType');
    return true;
  };

  const validateEstimatedNumberOfParticipants = (): boolean => {
    const numParticipants = Number(numberOfParticipants);
    if (numParticipants <= 0) {
      setFormErrorForField('numberOfParticipants', 'EstimatedNumberOfParticipantsPositive');
      return false;
    }
    if (numParticipants >= 100000) {
      setFormErrorForField('teamNames', 'EstimatedNumberOfParticipantsLarge');
      return false;
    }
    clearFormErrorForField('numberOfParticipants');
    return true;
  };

  /**
   * This will only be triggered when user press submit button
   * @param array
   */
  const allEmptyArray = (array: string[]) => {
    for (let item of array) {
      if (item !== '') {
        clearFormErrorForField('teamNames');
        return false;
      }
    }
    setFormErrorForField('teamNames', 'teamNameEmptyError');
    return true;
  };

  // Same reason as below
  useEffect( () => {
    validateTeamNames();
  }, [teamNames]);

  const validateTeamNames = (): boolean => {
    if (teamNames.length > 50) {
      setFormErrorForField('teamNames', 'teamNameLarge');
      return false;
    } else if (teamNames.length === 0) {
      setFormErrorForField('teamNames', 'teamNameEmpty');
      return false;
    } else if (containsOnlySpaces(getDirtyStringValues(teamNames))) {
      setFormErrorForField('teamNames', 'emptyStringTeamName');
      return false;
    }
    clearFormErrorForField('teamNames');
    return true;
  };

  // This will solve the issue when user delete a field that has error, but the validation logic is still shown error
  // That is because the validation logic will use the old data (The data before deletion due to set hook is async function)
  // Retrigger this validation logic once the setState hook update
  useEffect(() => {
    validateEmail();
  }, [collaborators]);

  const validateEmail = (): boolean => {
    if (collaborators.length > 10) { // Check if user enter more than 10 collaborators
      setFormErrorForField('collaborators', 'tooManyCollaborators');
      return false;
    } else if (collaborators.some(currentObj => currentObj.value.length > 500)) { // check is any email address is large than 500 characters long
      setFormErrorForField('collaborators', 'emailLengthTooLong');
      return false;
    } else if (containsOnlySpaces(getDirtyStringValues(collaborators))) {
      // we only validate the strings that are "dirty", if the dirty strings contains only empty string, we throw error
      // If user click Add button without touching it, the field will not be dirty, we won't throw error, (But it will be validate when user click submit button)
      setFormErrorForField('collaborators', 'emailEmpty');
      return false;
    } else if (collaborators.some(currentObj => currentObj.dirty && !isEmailValid(currentObj.value))) {
      // Since all the untouched email is consider as clean and valid, we only validate those dirty ones, if any email is dirty and invalid, we throw error.
      setFormErrorForField('collaborators', 'notValidEmail');
      return false;
    }
    clearFormErrorForField('collaborators');
    return true;
  }

  const participantsOnChangeHandler = ({ detail }: any) => {
    setNumberOfParticipants(detail.value);
  };

  const dateOnChangeHandler = ({ detail }: any) => {
    setCloseDate(detail.value)
  };

  return (
    <>
      <Form
        actions={
          <SpaceBetween
            size='xs'
            direction='horizontal'
          >
            <Button
              variant='link'
              onClick={openCancelModal}
            >
              {formatMessage({ id: 'cancel' })}
            </Button>
            <Button
              variant='primary'
              onClick={onSubmitHandler}
              loading={btnLoading}
            >
              {formatMessage({ id: 'submit' })}
            </Button>
          </SpaceBetween>
        }
      >
        <Container
          header={
            <Header variant='h2'>
              {formatMessage({ id: 'engagementDetails' })}
            </Header>
          }
        >
          <SpaceBetween
            size='l'
            direction='vertical'
          >
            <FormField
              label={formatMessage({ id: 'accountNameLabel' })}
              description={formatMessage({ id: 'accountNameDescription' })}
              errorText={formError.accountName}
            >
              <Input
                value={accountName}
                onChange={({ detail }) => setAccountName(detail.value)}
                placeholder={formatMessage({ id: 'accountNamePlaceHolder' })}
                onBlur={validateAccountName}
                disabled={isReopenMode}
              />
            </FormField>
            <FormField
              label={formatMessage({ id: 'sfdcIdLabel' })}
              description={formatMessage({ id: 'sfdcDescription' })}
              errorText={formError.sfdcID}
              info={
                <Link
                  variant='info'
                  onFollow={sfdcInfoHandler}
                >
                  {formatMessage({ id: 'info' })}
                </Link>
              }
            >
              <Input
                value={sfdcID}
                onChange={({ detail }) => setSfdcId(detail.value)}
                placeholder={formatMessage({ id: 'sfdcPlaceHolder' })}
                onBlur={validateSDFC}
                disabled={isReopenMode}
              />
            </FormField>
            <FormField
              label={formatMessage({ id: 'engagementNameLabel' })}
              description={formatMessage({ id: 'engagementNameDescription' })}
              errorText={formError.engagementName}
            >
              <Input
                value={engagementName}
                onChange={({ detail }) => setEngagementName(detail.value)}
                placeholder={formatMessage({ id: 'engagementNamePlaceholder' })}
                onBlur={validateEngagementName}
                disabled={isReopenMode}
              />
            </FormField>
            <FormField
              label={formatMessage({ id: 'surveyCloseDate' })}
              description={formatMessage({ id: 'surveyCloseDescription' })}
              errorText={formError.closeDate}
            >
              <DatePicker
                value={closeDate}
                onChange={dateOnChangeHandler}
                onBlur={validateDate}
                openCalendarAriaLabel={(selectedDate) =>
                  'Choose Date' +
                  (selectedDate ? `, selected date is ${selectedDate}` : '')
                }
                nextMonthAriaLabel={formatMessage({ id: 'nextMonth' })}
                placeholder={formatMessage({ id: 'surveyClosePlaceholder' })}
                previousMonthAriaLabel={formatMessage({ id: 'previousMonth' })}
                todayAriaLabel={formatMessage({ id: 'today' })}
                isDateEnabled={(date) => date >= new Date()}
              />
            </FormField>
            <FormField
              label={formatMessage({ id: 'engagementType' })}
              description={formatMessage({ id: 'engagementTypeDescription' })}
              errorText={formError.engagementType}
            >
              <Select
                selectedOption={engagementType}
                onChange={({ detail }) =>
                  setEngagementType(detail.selectedOption)
                }
                options={[
                  { label: formatMessage({ id: 'customer' }), value: 'Customer', description: formatMessage({ id: 'typeDescriptionC' }) },
                  { label: formatMessage({ id: 'partner' }), value: 'Partner', description: formatMessage({ id: 'typeDescriptionP' }) },
                ]}
                disabled={isEditMode || isReopenMode}
                selectedAriaLabel={formatMessage({ id: 'selected' })}
                placeholder={formatMessage({ id: 'engagementTypePlaceholder' })}
                onBlur={validateEngagementType}
              />
            </FormField>
            <FormField
              label={formatMessage({ id: 'teamNames' })}
              description={formatMessage({ id: 'teamNamesDescription' }, {
                u: (...str: any) => (<u key={str && str.toString()}>{str}</u>)
              })}
              errorText={formError.teamNames}
            >
              <DynamicInputFields
                fields={value?.teams || teamNames}
                isReopenMode={isReopenMode}
                setParentVariables={setTeamNames}
                onBlur={validateTeamNames}
                placeholder='enterTeamName'
                attributeName='teamName'
                addBtnText='addATeam'
                maximumSize={50}
              />
            </FormField>
            {displayCollaborator && <FormField
              label={formatMessage({ id: 'collaborators' })}
              description={formatMessage({ id: 'collaboratorDesc' })}
              errorText={formError.collaborators}
              info={
                <Link
                  variant='info'
                  onFollow={collaboratorInfoHandler}
                >
                  {formatMessage({ id: 'info' })}
                </Link>
              }
            >
              <DynamicInputFields
                fields={value?.collaborators || collaborators}
                isReopenMode={isReopenMode}
                setParentVariables={setCollaborators}
                placeholder='collaboratorPlaceholder'
                attributeName='collaborators'
                addBtnText='addCollaborator'
                maximumSize={10}
                onBlur={validateEmail}
              />
            </FormField>}
            <FormField
              label={
                <span>
                  {formatMessage(
                    { id: 'estimatedNumberOfParticipantsLabel' },
                    { optional: <i>- optional</i> }
                  )}
                </span>
              }
              description={formatMessage({
                id: 'estimatedNumberOfParticipantsDescription',
              })}
              errorText={formError.numberOfParticipants}
            >
              <Input
                placeholder={formatMessage({
                  id: 'estimatedNumberOfParticipantsPlaceholder',
                })}
                onBlur={validateEstimatedNumberOfParticipants}
                value={numberOfParticipants}
                onChange={participantsOnChangeHandler}
                inputMode='numeric'
                type='number'
                disabled={isReopenMode}
              />
            </FormField>
            <FormField>
              <Checkbox
                checked={isAtp}
                disabled={isEditMode || isReopenMode}
                onChange={({ detail }) => setIsAtp(detail.checked)}
                description={formatMessage({ id: 'isATPDescription' })}
              >
                {formatMessage({ id: 'isATP' })}
              </Checkbox>
            </FormField>
            <FormField>
              <Checkbox
                checked={displayIndividual}
                disabled={isEditMode || isReopenMode}
                onChange={({ detail }) => setDisplayIndividual(detail.checked)}
                description={formatMessage({
                  id: 'displayIndividualDescription',
                })}
              >
                {formatMessage({ id: 'displayIndividual' })}
              </Checkbox>
            </FormField>
            <FormField>
              <Checkbox
                checked={internal}
                disabled={isEditMode || isReopenMode}
                onChange={({ detail }) => setInternal(detail.checked)}
                description={formatMessage({ id: 'internalTestDescription' })}
              >
                {formatMessage({ id: 'internalTest' })}
              </Checkbox>
            </FormField>
          </SpaceBetween>
        </Container>
      </Form>
      <CancelModal
        visible={visible}
        onDismissHandler={onDismissHandler}
        onConfirmHandler={onConfirmHandler}
        onCancelHandler={onCancelHandler}
      />
    </>
  );
};

export default LnaFormContent;
