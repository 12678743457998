import {
  TopNavigation,
  TopNavigationProps,
} from '@amzn/awsui-components-react';
import { deleteNotification, showNotification } from '../../redux/reducers/notificationReducer';

import { UUID } from '../../util/uuid';
import { changeLanguage } from '../../redux/reducers/languageReducer';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { Cache } from '@aws-amplify/cache';
import { getCookieByName } from '../../util/cookies';
import { displayAtpFeature } from '../../util/featureControlHelper';
import jwt_decode from "jwt-decode";

const identity = {
  href: '',
  logo: {
    src: 'https://a0.awsstatic.com/libra-css/images/logos/aws_smile-header-desktop-en-white_59x35@2x.png',
  },
  title: 'Learning Needs Analysis',
};

const i18nStrings = {
  searchIconAriaLabel: 'Search',
  searchDismissIconAriaLabel: 'Close search',
  overflowMenuTriggerText: 'More',
  overflowMenuTitleText: 'All',
  overflowMenuBackIconAriaLabel: 'Back',
  overflowMenuDismissIconAriaLabel: 'Close menu',
};
type ObjectKey = keyof typeof localMap;

const localMap = {
  en: 'English (US)',
  zh_CN: '中文 (简体)',
  zh_TW: '中文 (繁体)',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
  ja: '日本語',
  ko: '한국인',
  pt_BR: 'Português',
  de: 'Deutsch'
};

const LNATopNavigation = () => {
  const dispatch = useDispatch();
  const [localeText, setLocaleText] = useState<string>('English (US)');
  const { formatMessage } = useIntl();
  const showAtpFeature:boolean = displayAtpFeature();
  const authToken = showAtpFeature == true ? '' : getCookieByName('idToken');
  const decoded:any = showAtpFeature == true ? '' : jwt_decode(authToken);
  // Will modify the logic below later using getPermissionAPI: https://sim.amazon.com/issues/ilt-tomi-387
  const posixGroups = showAtpFeature == true ? ['lna-admin', 'lna-user'] : decoded['custom:posix_groups'];
  const userProfile = Cache.getItem('userProfile');
  const nameAtp = userProfile === null ? '' : userProfile.fullName;
  const fullName = showAtpFeature == true ? nameAtp : `${decoded.given_name} ${decoded.family_name}`;
  const emailAtp = userProfile === null ? '' : userProfile.email;
  const email = showAtpFeature == true ? emailAtp : decoded.email;
  const public_provider_name = showAtpFeature == true ? Cache.getItem('userProfile').public_provider_name : '';
  // User needs to be in either LNA user or LNA admin POSIX group to see the website
  if (posixGroups.indexOf('lna-user') < 0 && posixGroups.indexOf('lna-admin') < 0) {
    useEffect(() => {
      dispatch(deleteNotification()); // There can only exist one notification so we need to delete other notifications first
      dispatch(showNotification({
        type: 'error',
        dismissible: true,
        headerText: formatMessage({ id: 'notInGroupErrorTitle'}),
        content: formatMessage({ id: 'notInGroupErrorContent' }, {
          link: (...str: any) => (<a target='_blank' key={str && str.toString()} href={str.toString()}>{str}</a> )
        }),
        id: UUID()
      }));
    }, [posixGroups]);
  }
  let loggedInfo = formatMessage({id: 'userLoginText'}, { name: `${fullName}`})
  if (posixGroups.indexOf('lna-admin') >= 0) {
      loggedInfo = formatMessage({id: 'adminLoginText'}, { name: `${fullName}`})
  }

  const utilities: ReadonlyArray<TopNavigationProps.Utility> = [
    {
      type: 'button',
      iconName: 'status-info',
      title: 'Notification',
      ariaLabel: 'Notifications (unread)',
    },
    {
      type: 'menu-dropdown',
      text: localeText,
      description: 'Language selection',
      items: [
        { id: 'en', text: 'English (US)' },
        { id: 'zh_CN', text: '中文 (简体)' },
        { id: 'zh_TW', text: '中文 (繁体)' },
        { id: 'es', text: 'Español' },
        { id: 'de', text: 'Deutsch' },
        { id: 'fr', text: 'Français' },
        { id: 'it', text: 'Italiano' },
        { id: 'ja', text: '日本語' },
        { id: 'ko', text: '한국인' },
        { id: 'pt_BR', text: 'Português' }
      ],
      onItemClick: ({ detail }) => {
        const id = detail.id as ObjectKey;
        const text = localMap[id];
        setLocaleText(text);
        dispatch(changeLanguage(id));
      },
    },
    {
      type: 'menu-dropdown',
      text: loggedInfo,
      items: [
        { id: 'userName', text: `${fullName}` },
        { id: 'email', text: `${email}` }
      ],
    },
  ];
  return (
    <TopNavigation
      identity={identity}
      i18nStrings={i18nStrings}
      utilities={utilities}
    />
  );
};

export default LNATopNavigation;
