import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import Main from './main';
import { Provider } from 'react-redux';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import store from '../redux/store';

/**
 * refetchOnWindowFocus: If a user leaves your application and returns to stale data, React Query automatically requests fresh data for you in the background
 */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

// Class App is the 'output' generated on every build,
// it is what you will see on the webpage.
export default function App() {
  // Create a client

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Main />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  );
}
