import '../styles/index.scss';

import { ILanguageObject, merge } from '../common/mergeObject';
import { LnaCreationForm, LnaEditForm, LnaReopenForm } from './LNA/lna-form';
import { NotificationState, deleteNotification, markNewLocationDone } from '../redux/reducers/notificationReducer';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import Brazil from '../strings/pt_BR.json';
import Chinese from '../strings/zh_CN.json'
import EngagementDetail from './LNA/engagementDetail';
import Engagements from './LNA/engagements';
import English from '../strings/en.json';
import France from '../strings/fr.json';
import Germany from '../strings/de.json';
import { GlobalState } from '../redux/reducers';
import HardChinese from '../strings/zh_TW.json';
import { IntlProvider } from 'react-intl';
import Italy from '../strings/it.json';
import Japanese from '../strings/ja.json';
import Korean from '../strings/ko.json';
import LNATopNavigation from './navigation/top-navigation';
import { Authenticator } from './authenticator/Authenticator';
import { ConditionalWrapper } from './helper/ConditionalWrapper';
import { LanguageState } from '../redux/reducers/languageReducer';
import ServiceHomepage from './home';
import Spain from '../strings/es.json';
import { setApiConfig } from '../redux/reducers/apiReducer';
import { configureAmplify } from '../util/amplify';
import { displayAtpFeature } from '../util/featureControlHelper';

const Main = () => {
  const { currentLocale } = useSelector<GlobalState, LanguageState>(state => state.language);
  const [message, setMessage] = useState<any>(English);
  const dispatch = useDispatch();
  const { shouldShowNotification, notification } = useSelector<GlobalState, NotificationState>(state => state.notifications);

  // We can refactor this logic later
  let ChineseObject = Chinese as ILanguageObject;
  ChineseObject = merge(English, Chinese);

  let GermanyObject = Germany as ILanguageObject;
  GermanyObject = merge(English, Germany);

  let SpainObject = Spain as ILanguageObject;
  SpainObject = merge(English, Spain);

  let FranceObject = France as ILanguageObject;
  FranceObject = merge(English, France);

  let ItalyObject = Italy as ILanguageObject;
  ItalyObject = merge(English, Italy);

  let JapaneseObject = Japanese as ILanguageObject;
  JapaneseObject = merge(English, Japanese);

  let KoreanObject = Korean as ILanguageObject;
  KoreanObject = merge(English, Korean);

  let BrazilObject = Brazil as ILanguageObject;
  BrazilObject = merge(English, Brazil);

  let HardChineseObject = HardChinese as ILanguageObject;
  HardChineseObject = merge(English, HardChinese);

  useEffect(() => {
    switch (currentLocale) {
      case 'en':
        setMessage(English);
        break;
      case 'zh_CN':
        setMessage(ChineseObject);
        break;
      case 'de':
        setMessage(GermanyObject);
        break;
      case 'es':
        setMessage(SpainObject);
        break;
      case 'fr':
        setMessage(FranceObject);
        break;
      case 'it':
        setMessage(ItalyObject);
        break;
      case 'ja':
        setMessage(JapaneseObject);
        break;
      case 'ko':
        setMessage(KoreanObject);
        break;
      case 'pt_BR':
        setMessage(BrazilObject);
        break;
      case 'zh_TW':
        setMessage(HardChineseObject);
        break;
      default:
        setMessage(English);
        break;
    }
  }, [currentLocale]);
  /**
   * We need to fetch the endpoint and stage info for all api calls and we need to do this only once when the App starts
   */
  useEffect(() => {
    const getEndpoint = async () => {
      const res = await fetch('/settings.json', {
        method: 'GET',
        mode: 'cors'
      });
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      const data = await res.json();
      dispatch(setApiConfig({
        stage: data.STAGE,
        endpoint: data.API_ENDPOINT
      }));
      return data;
    };

    getEndpoint().catch(console.error);
  }, []);

  configureAmplify();

  const location = useLocation();

  // This function will be executed every time this is an URL change
  useEffect(() => {
    // Notification will be shown on new page only when displayInNewLocation === true and displayInNewLocationDone === false
    if (notification?.displayInNewLocation === true && notification?.displayInNewLocationDone === false) {
      dispatch(markNewLocationDone());
    } else if (shouldShowNotification) {
      // We want to delete notifications once we are on new page to have a clean start, add this shouldShowNotification === true check to reduce necessary actions
      dispatch(deleteNotification());
    }
  }, [location]);
  const showAtpFeature:boolean = displayAtpFeature();
  return (
    <IntlProvider
      locale='en'
      messages={message}
      defaultLocale='en'
    >
      <ConditionalWrapper
                      condition={showAtpFeature}
                      wrapper={children => <Authenticator globals={window}>{children}</Authenticator>}
                  >
        <div className='awsui-visual-refresh'>
          <LNATopNavigation />
          <Routes>
            <Route
              path='/'
              element={<ServiceHomepage />}
            />
            <Route
              path='/lna-creation'
              element={<LnaCreationForm />}
            />
            <Route
              path='/engagements'
              element={<Engagements />}
            />
            <Route
              path='/engagement-detail/:engagementId'
              element={<EngagementDetail />}
            />
            <Route
              path='/edit-engagement/:engagementId'
              element={<LnaEditForm />}
            />
            <Route
                path='/reopen-engagement/:engagementId'
                element={<LnaReopenForm />}
            />
          </Routes>
        </div>
      </ConditionalWrapper>
    </IntlProvider>
  );
};

export default Main;
