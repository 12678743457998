import { ExpandableSection, Input } from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';

import { AMAZON_INTERNAL_DOMAIN } from './const';
import HelpPanel from '@amzn/awsui-components-react/polaris/help-panel';

const topics = (topic: string, formatMessage?: any): React.ReactNode => {

  const [emails, setEmails] = useState(AMAZON_INTERNAL_DOMAIN);
  const [searchEmail, setSearchEmail] = useState('');

  useEffect(() => {
    if (searchEmail) {
      setEmails(emails.filter(email => email.indexOf(searchEmail) >= 0));
    } else {
      setEmails(AMAZON_INTERNAL_DOMAIN);
    }
  }, [searchEmail]);

  const object = {
  // List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
  lna: (
    <HelpPanel
      header={<h2>LNA</h2>}
    >
      <p>{formatMessage({ id: 'helpLNAP1' })}</p>
      <p>
        {formatMessage({ id: 'helpLNAP2' },
        {
          a: (...str: any) => (<a key={str && str.toString()} target='_blank' href='https://w.amazon.com/bin/view/AWS_Training_and_Certification/Global_Sales_Strategy_and_Programs/Learning_Needs_Analysis_Tool/FAQ/'>{str}</a>),
          b: (...str: any) => (<a key={str && str.toString()} target='_blank' href='https://w.amazon.com/bin/view/AWS_Training_and_Certification/Global_Sales_Strategy_and_Programs/Learning_Needs_Analysis_Tool/ContactUs/#HOfficeHours'>{str}</a> ),
          c: (...str: any) => (<a key={str && str.toString()} target='_blank' href='https://sim.amazon.com/issues/create?template=9389e4f6-bfd7-46cd-9ea1-ab0b93cab8c5'>{str}</a> )
        })}
      </p>
    </HelpPanel>
  ),
  createLna:(
    <HelpPanel
      header={<h2>{formatMessage({ id: 'createLna' })}</h2>}
    >
      <p>{formatMessage({ id: 'createPageHelp1' })}</p>
      <p>{formatMessage({ id: 'createPageHelp2' }, {
        a: (...str: any) => (<a key={str && str.toString()} target='_blank' href='https://sim.amazon.com/issues/create?template=9389e4f6-bfd7-46cd-9ea1-ab0b93cab8c5'>{str}</a>)
      })}</p>
    </HelpPanel>
  ),
  engagements: (
    <HelpPanel header={<h2>{formatMessage({ id: 'engagementsHelp' })}</h2>}>
      <p>{formatMessage({ id: 'engagementsHelp1' })}</p>
      <p>{formatMessage({ id: 'engagementsHelp2' })}</p>
      <p>{formatMessage({ id: 'engagementsHelp3' })}</p>
    </HelpPanel>
  ),
  editInfoLink: (
    <HelpPanel
      header={<h2>{formatMessage({ id: 'editEngagementHelp' })}</h2>}
    >
      <p>
        {formatMessage({ id: 'editEngagementHelpP4' },
        {
          a: (...str: any) => (<a key={str && str.toString()} target='_blank' href='https://sim.amazon.com/issues/create?template=9389e4f6-bfd7-46cd-9ea1-ab0b93cab8c5'>{str}</a>)
        })}
      </p>
    </HelpPanel>
  ),
  engagementDetail: (
    <HelpPanel header={<h2>This is header of Engagement detail</h2>}>
      <p>This is the main content for engagement details</p>
    </HelpPanel>
  ),
  editEngagement: (
    <HelpPanel
      header={<h2>{formatMessage({ id: 'editEngagementHelp' })}</h2>}
    >
      <p>{formatMessage({ id: 'editEngagementHelpP1' })}</p>
      <p>{formatMessage({ id: 'editEngagementHelpP2' })}</p>
      <p>{formatMessage({ id: 'editEngagementHelpP3' })}</p>
    </HelpPanel>
  ),
  sfdc: (
    <HelpPanel header={<h2>{formatMessage({ id: 'sfdcHelpTitle' })}</h2>}>
      <p>{formatMessage({ id: 'sfdcHelpContentOne' })}</p>
      <p>{formatMessage({ id: 'sfdcHelpContentTwo' })}</p>
      <p>{formatMessage({ id: 'sfdcHelpContentThree' })}</p>
    </HelpPanel>
  ),
  home: (
    <HelpPanel header={<h2>{formatMessage({ id: 'sfdcHelpTitle' })}</h2>}>
      <p>{formatMessage({ id: 'sfdcHelpContentOne' })}</p>
      <p>{formatMessage({ id: 'sfdcHelpContentTwo' })}</p>
      <p>{formatMessage({ id: 'sfdcHelpContentThree' })}</p>
    </HelpPanel>
  ),
  collaboration: (
    <HelpPanel header={<h2>{formatMessage({ id: 'collaborators' })}</h2>}>
      <p>{formatMessage({ id: 'collaboratorHelp1' })}</p>
      <p>{formatMessage({ id: 'collaboratorHelp2' })}</p>
      <p>{formatMessage({ id: 'collaboratorHelp3' })}</p>
      <ExpandableSection headerText={formatMessage({ id: 'amazonDomain' })}>
        <Input
          value={searchEmail}
          placeholder={formatMessage({ id: 'searchEmailPlaceholder' })}
          onChange={e => setSearchEmail(e.detail.value)}
        />
        <ul>
          {emails.map((domain) => <li key={domain}>{domain}</li>)}
        </ul>
      </ExpandableSection>
    </HelpPanel>
  )
  }
  return object[topic as keyof typeof object];
};

export default topics;
