import { Button, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

const HomepageHeader = () => {

  const { formatMessage } = useIntl();

  return (
    <SpaceBetween size='m'>
      <Header
        variant='h1'
        description={formatMessage({ id: 'homePageHeaderInfo' })}
        actions={
          <SpaceBetween
            size='xs'
            direction='horizontal'
            className='button-group'
          >
            <Button
              href='#/engagements'
            >
              {formatMessage({ id: 'viewEngagements' })}
            </Button>
            <Button
              href='#/lna-creation'
              variant='primary'
            >
              {formatMessage({ id: 'createEngagement' })}
            </Button>
          </SpaceBetween>
        }
      >
        {formatMessage({ id: 'homePageHeader' })}
      </Header>
    </SpaceBetween>
  );
};

export default HomepageHeader;
